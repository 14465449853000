import React, { useEffect, useState,  } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import "../styles/parties.css"
import Cookies from 'js-cookie';
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";
import Party_Default from "./Party_Default";

function View_party() {
  const navigate = useNavigate();
  const ID = Cookies.get("user_id");
  const { partyId } = useParams();
  console.log("Party ID:", partyId);
  const [parties, setParties] = useState([]);
  const [selectedParty, setSelectedParty] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [balance, setBalance] = useState(0);
 

  const fetchParties = () => {
    axios
      .get(`${config.base_url}/fetch_parties/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          const fetchedParties = res.data.parties.map((i) => ({
            id: i.id,
            name: i.party_name,
            gstType: i.gst_type,
            gstIn: i.gstin,
            mailId: i.email,
            openingBalance: i.opening_balance,
            balance: i.opening_balance,
            creditLimit: i.credit_limit,
            billing_address: i.billing_address,
            phone: i.phone_number,
            state: i.state,
            to_pay: i.to_pay,
            to_receive: i.to_recieve,
            date: i.date,
          }));
          console.log("Mapped Parties:", fetchedParties);  
          setParties(fetchedParties);

         
        }
      })
      .catch((err) => {
        console.error("Error fetching parties:", err);
      });
  };

  const fetchPartyDetails = (partyId) => {
    axios
      .get(`${config.base_url}/fetch_party_details/${partyId}/${ID}`)
      .then((res) => {
        if (res.data.status) {
          setSelectedParty(res.data.party);
          setTransactions(res.data.all_transactions || []);
          setBalance(res.data.Balance || 0);
        }
        console.log("fetched data",res.data)
      })
      .catch((err) => {
        console.error("Error fetching party details:", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };


  // useEffect(() => {
  //   fetchParties(); 
  // }, []);

  
  // useEffect(() => {
    
  //   if (parties.length > 0) {
  //     if (partyId && partyId !== "null") {
  //       fetchPartyDetails(partyId); 
  //     } else {
      
  //       fetchPartyDetail  // }, [parties, partyId]); 
 
  //     }
  //   }

  
  useEffect(() => {
    if (partyId) {
      fetchPartyDetails(partyId);
      fetchParties();
      
    }
  }, [partyId]);
 


  useEffect(() => {
    fetchParties();
  }, []);
  


  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  
  function handleDeleteParty(partyId, partyName) {
    Swal.fire({
      title: `Delete Party - ${partyName}?`,
      text: "All transactions will be deleted!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.base_url}/delete_party/${partyId}/`)
          .then(() => {
            Toast.fire({
              icon: "success",
              title: "Party Deleted successfully",
            });
  
            setParties((prevParties) => {
              const updatedParties = prevParties.filter((party) => party.id !== partyId);
  
              if (updatedParties.length > 0) {
                const nextPartyId = updatedParties[0].id;
                navigate(`/view_party/${nextPartyId}`);
                fetchPartyDetails(nextPartyId);
              } else {
                navigate("/default_party");
                setSelectedParty(null); 
              }
  
              return updatedParties; 
            });
          })
          .catch((err) => {
            console.error("Error deleting party:", err);
          });
      }
    });
  }
  
 
 
  function printTable() {
    var divToPrint = document.getElementById("transactionTable");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }



function ExportToExcel(type, fn, dl) {
  const elt = document.getElementById("transactionTable");

  const partyName = selectedParty?.party_name || 'default_party_name'; 

  console.log("partyName:", partyName);

  
  const fileName = fn && typeof fn === "string" ? fn : `${partyName}_transactions`; 

  console.log("Generated file name:", fileName)
  const validType = type && typeof type === 'string' ? type : 'xlsx';

  console.log("Generated file type:", validType);


  const validFileName = `${fileName}.${validType}`;

  console.log("Final generated file name:", validFileName);

  const wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });


  return dl
      ? XLSX.write(wb, { bookType: validType, bookSST: true, type: "base64" })
      : XLSX.writeFile(wb, validFileName);  
}




  function searchTable1(){
    var rows = document.querySelectorAll('#partyTable tbody tr');
    var val = document.getElementById('search1').value.trim().replace(/ +/g, ' ').toLowerCase();
    rows.forEach(function(row) {
      var text = row.textContent.replace(/\s+/g, ' ').toLowerCase();
      row.style.display = text.includes(val) ? '' : 'none';
    });
  }
  function searchTable2(){
    var rows = document.querySelectorAll('#transactionTable tbody tr');
    var val = document.getElementById('search2').value.trim().replace(/ +/g, ' ').toLowerCase();
    rows.forEach(function(row) {
      var text = row.textContent.replace(/\s+/g, ' ').toLowerCase();
      row.style.display = text.includes(val) ? '' : 'none';
    });
  }

  const partyPdf = (partyId) => {
    console.log("Party ID before sending:", partyId); // Log this to debug
    if (!partyId) {
      console.error("Invalid Party ID:", partyId);
      Swal.fire({
        icon: "error",
        title: "Invalid Party ID",
        text: "Please select a valid party.",
      });
      return;
    }

    var data = {
      Id: ID,
      party_id: partyId,
    };

    axios
      .get(`${config.base_url}/party_pdf/`, {
        responseType: "blob",
        params: data,
      })
      .then((res) => {
        console.log("PDF RES=", res);

        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.href = fileURL;
        a.download = `${selectedParty.party_name}_Transactions.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((err) => {
        console.error("Error downloading PDF:", err);
      });
  };

  const handleClick = () => {
    if (selectedParty) {
      console.log("Clicked Party ID:", selectedParty.id); 
      partyPdf(selectedParty.id);
    } else {
      Swal.fire({
        icon: "error",
        title: "No Party Selected",
        text: "Please select a party first.",
      });
    }
  };
  return (
   
    
    // <div className="container-scroller"  style={{ background: "rgb(130, 144, 199)" }}>
    // <Navbar />
    // <div className="container-fluid page-body-wrapper d-flex">
    //   <Sidebar />
    <div className="content-body " style={{ background: "rgb(130, 144, 199)" }}>
 <Navbar />
 <div className="container-fluid page-body-wrapper d-flex">
   <Sidebar />
    <div className="container whhole"   style={{ minHeight: "100vh" }}>
      <div className="main-panel p-4 px-3 py-2" style={{ background: "#a9a9a961" }}>
        <div className="content-wrapper">
        
          <div className="row">
          
            <div
              className="container col-md-4 table-responsive mb-3 firstcolumn-div"
              style={{ padding: "20px", borderRadius: "8px", width: "fit-content" }}
            >
              <h2 className="text-white my-3 ml-2">PARTY</h2>
              <div className="search-bar ml-2 mb-3">
                <div className="input-group">
                  <input
                    type="text"
                    autoComplete="off"
                     id="search1"
                    onKeyUp={searchTable1}
                    className="form-control"
                    placeholder="Search"
                  />
                  <button
                    onClick={() => navigate("/add_party")}
                    className="btn col-2 ml-2 button-hover"
                  >
                    <i className="fa-solid fa-plus"></i>
                  </button>
                </div>
              </div>
              <table className="table" id="partyTable">
                <thead className="p-0">
                  <tr>
                    <th 
                      style={{ backgroundColor: "#3d4465", color: "white",border: "none" }}
                      className="col-8 fs-6"
                    >
                      PARTY
                    </th>
                    <th
                      style={{ backgroundColor: "#3d4465", color: "white",border: "none" }}
                      className="col-4 fs-6"
                      colSpan="2"
                    >
                      OPENING BALANCE
                    </th>
                  </tr>
                </thead>
                <tbody  style={{ backgroundColor: "white" }}>
                {parties.map((party) => (
                  
                    <tr style={{ fontSize: "16px" }} key={party.id}>
                      <td className="col-4">
                      <div className="row">
                      <div className="col-md-6">
                        <a
                          id="filterByName"
                          onClick={() => navigate(`/view_party/${party.id}`)}
                          title="Show"
                          style={{ color: "black", cursor: "pointer" }}
                          // className={selectedParty?.id === party.id ? "table-active" : ""}
                         
                        >
                          {party.name}
                        </a>
                        </div>
                        </div>
                      </td>
                      <td
                        className="col-4"
                        style={{
                          color: party.to_receive === true? "rgb(71, 216, 71)" : "crimson",
                        }}
                      >
                        {party.openingBalance}
                      </td>
                      <td className="col-4">
                        <div className="dropdown show col">
                          <a
                            className="dropdown partyy"
                            href="#"
                            role="button"
                            id="dropdownMenuLink"
                            data-toggle="dropdown"
                            style={{ color: "gray" }}
                            onMouseOver={(e) => (e.target.style.color = "red")}
                            onMouseOut={(e) => (e.target.style.color = "gray")}
                          >
                            <i className="fa-solid fa-ellipsis-vertical ml-2"></i>
                          </a>
                          <div className="dropdown-menu">
                            <a
                              title="Edit Profile"
                              id="editt"
                              className="dropdown-item"
                              onClick={() => navigate(`/edit_party/${party.id}`)}
                            >
                              Edit
                              &nbsp;<span className="fa fa-edit pe-2" style={{ color: "#e4a11b" }}></span>
                            </a>
                            <a
                              className="dropdown-item"
                              onClick={() => navigate(`/history_party/${party.id}`)}
                              title={`${party.party_name}'s History`}
                            >
                              History
                              &nbsp;<span
                                className="fa fa-history pe-2"
                                style={{ color: "rgb(12, 79, 234)" }}
                              ></span>
                            </a>
                            <a
                              className="dropdown-item"
                              onClick={() => handleDeleteParty(party.id, party.name)}
                            
                            >
                              Delete
                              &nbsp;<span
                                className="fa-solid fa-trash"
                                style={{
                                  paddingInlineStart: "0.1rem",
                                  paddingInlineEnd: "0.7rem",
                                  color: "red",
                                }}
                              ></span>
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

               {selectedParty && (
      
        <div className="container col-md-8">
        <div className="name-div" style={{ padding: "20px", borderRadius: "8px" }}>
        <div className="row mt-2">
        <div className="col-12">
          <h3
            // id="namee"
            style={{
              textTransform: "uppercase",
              fontSize: "25px",
              color: "white",
              marginLeft: "5px",
             
            }}
            

          >
            {selectedParty?.party_name}
          </h3>
          </div>
          </div>
          
          <div className="row mt-2 ml-md-1" id="thirdTable">
            <div className="col-md-6 partiess">
              <label>Phone:&nbsp;</label>
              <span>{selectedParty?.phone_number}</span>
              <br />
              <label>Email:&nbsp;</label>
              <span>
                {selectedParty?.email ? (
                  selectedParty.email
                ) : (
                  <a onClick={() => navigate(`/party/edit/${selectedParty.id}`)}>
                    <i className="fa-solid fa-envelope"></i> Add Email
                  </a>
                )}
              </span>
              <br />
              <label>Balance:&nbsp;</label>
              <span>{balance.toFixed(2) || "0"}</span>
              <br />
            </div>
            <div className="col-md-6 partiess">
              <label>Address:&nbsp;</label>
              <span>
                {selectedParty?.billing_address  ? (
                  selectedParty.billing_address 
                ) : (
                  <a onClick={() => navigate(`/edit_party/${selectedParty.id}`)}>
                    <i className="fa-solid fa-location-dot"></i> Add Address
                  </a>
                )}
              </span>
              <br />
              <label>GSTIN:&nbsp;</label>
              <span>
                 {selectedParty?.gstin ? (
                  selectedParty.gstin
                ) : (
                  <a onClick={() => navigate(`/edit_party/${selectedParty.id}`)}>
                    <i className="fa-solid fa-calculator"></i> Add GSTIN
                  </a>
                )}
              </span>
            </div>
          </div>
        </div>
            
            

            <div
                id="pdf"
                className="container col transaction-div mt-3"
                style={{ padding: "30px", borderRadius: "8px" }}
              >
            <div className="row ">
                  <div className="col-md-6">
                    <h3 style={{ color: "white" }} className="ml-md-2">
                      Transactions
                    </h3>
                    <input
                      className="form-control mt-2 w-100"
                      type="text"
                      autoComplete="off"
                      id="search2"
                      onKeyUp={searchTable2}
        
                      placeholder="Search"
                    />
                  </div>
                  <div className="col-md-6 mt-1 mt-md-0">
                  
                   {selectedParty && (
                        <button className="btn ml-1 button-hover" onClick={handleClick}>
                       PDF<i className="fa-solid fa-file-pdf" ></i>
                         </button>
                              )}
                    <button className="btn ml-1 button-hover" onClick={printTable}>
                      Print <i className="fa-solid fa-print"></i>
                    </button>
                    <button className="btn ml-1 button-hover" onClick={ExportToExcel}>
                      Excel <i className="fa-solid fa-file-excel"></i>
                    </button>
                  </div>
                </div>
                <br />
                <div
                  className="table table-responsive"
                  id="pdftable"
                  style={{ padding: "20px" }}
                >

            <table className="table" id="transactionTable">
             
              <thead>
                      <tr>
                      <th
                          className="fs-6"
                          style={{ backgroundColor: "#3d4465", color: "white",border: "none"  }}
                        >
                        #
                        </th>
                        <th
                          className="fs-6"
                          style={{ backgroundColor: "#3d4465", color: "white",border: "none"  }}
                        >
                         TYPE
                        </th>
                        <th
                          className="fs-6"
                          style={{ backgroundColor: "#3d4465", color: "white" ,border: "none" }}
                        >
                          NUMBER
                        </th>
                       
                        <th
                          className="fs-6"
                          style={{ backgroundColor: "#3d4465", color: "white" ,border: "none" }}
                        >
                         DATE
                        </th>
                        <th
                          className="fs-6"
                          style={{ backgroundColor: "#3d4465", color: "white",border: "none"  }}
                        >
                          TOTAL
                        </th>
                        <th
                          className="fs-6"
                          style={{ backgroundColor: "#3d4465", color: "white",border: "none"  }}
                        >
                          BALANCE
                        </th>
                      </tr>
                    
              </thead>
              <tbody style={{backgroundColor:"white", textAlign:"center"}}>
                {transactions.map((transaction, index) => (
                  <tr key={transaction.id}>
                    <td>{index + 1}</td>
                    <td>{transaction.type}</td>
                    <td>{transaction.number}</td>
                    <td>{transaction.date}</td>
                    <td>{transaction.amount}</td>
                    <td>{transaction.balance}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        </div>
      
      
      )}
  
  </div>
</div>
</div>
</div>
</div>
</div>



  );
}

export default View_party;
